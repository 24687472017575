import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import "../scss/ContactForm.scss";
import { Helmet } from "react-helmet";

export default function PublicSpeaking() {
const data = useStaticQuery(graphql`
query MyQuery3856 {
    allContentfulHeroImage(filter: { name: { eq: "Public Speaking Image" } }) {
    edges {
        node {
        heroImage {
            altText
            image {
            file {
                url
            }
            id
            }
            name
        }
        }
    }
    }
}
`)
  const [occupation, setOccupation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [isEmailInputEnabled, setIsEmailInputEnabled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  const handleOccupationChange = (e) => {
    setOccupation(e.target.value);
    setErrorMessage(''); // Clear error message when a radio button is selected
  };

  const handleEmailInputChange = (e) => {
    if (errorMessage) {
      setErrorMessage(''); // Clear the error message when the user starts typing a different email
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('MERGE0');
    console.log("Selected Radio Button Value: ", selectedRadioValue);
    console.log("Email: ", email);
  
    const emailInput = document.querySelector('input[type="email"]');
    const enteredEmail = emailInput.value.trim().toLowerCase();
  
    const disallowedDomains = ['@gmail', '@yahoo', '@icloud', '@aol', '@mac', '@hotmail', '@outlook', '@proton', '@protonmail', '@zohocorp', '@gmx', '@yandex', '@hubspot', '@neo', '@thunder', '@degcos', '@me'];
  
    const containsDisallowedDomain = disallowedDomains.some(domain => enteredEmail.includes(domain));
  
    // Check for 'info' or 'contact' as prefixes
    const disallowedPrefixes = ['info', 'contact'];
    const emailPrefix = enteredEmail.split('@')[0]; // Extract the part before '@'
  
    const containsDisallowedPrefix = disallowedPrefixes.some(prefix => emailPrefix === prefix);
  
    if (!occupation) {
      setErrorMessage('Please select an occupation');
      return;
    } else if (containsDisallowedDomain) {
      setErrorMessage('Please use your business or educational email');
      console.error('Disallowed email domain used:', enteredEmail);
      return;
    } else if (containsDisallowedPrefix) {
        setErrorMessage('Please use your business or educational email');
      console.error('Disallowed email prefix used:', enteredEmail);
      return;
    }
  
    // If validation passes, proceed with form submission
    setErrorMessage('');
    console.log('Form submitted successfully!');
    e.target.submit(); // Manually trigger form submission
  };
  

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

//   const labelStyle = windowWidth <= 800 ? { paddingLeft: '0' } : { paddingLeft: '62px' };
const labelStyle =
  windowWidth <= 800
    ? { paddingLeft: '0' }
    : windowWidth <= 1000
    ? { paddingLeft: '46px' }
    : windowWidth <= 1250
    ? { paddingLeft: '52px' }
    : windowWidth <= 1536
    ? { paddingLeft: '62px' }
    : windowWidth <= 1700
    ? { paddingLeft: '72px' }
    : windowWidth <= 2000
    ? { paddingLeft: '80px' }
    : { paddingLeft: '0px' };

  
  
  return (
    <>
    <Helmet>
        <title>Contact The Fresh Kid</title>
        <meta name="description" content="The Fresh Kid - Luxury data Engineer and Analyst - Make an Enquiry" />
        <meta name="keywords" content="luxury data engineer, luxury data analyst" />
        <h1>Contact Me</h1>
        <h2>Leave me a message and I will respond in a timely fashion</h2>
    </Helmet>
    <div className="contentFeatureContactMe">
    <div className="contentContainerContactMe">
        <div className="formContactMe">
            <form action="https://thefreshkid.us8.list-manage.com/subscribe/post" method="POST" target="_blank" onSubmit={handleSubmit}>
                <input type="hidden" name="u" value="8abd2b2ed117ea16fd4a66f6d" />
                <input type="hidden" name="id" value="51ef6ddef7" />
                <p className="formTitleContactMe">Contact Me</p>
                <p className="formSubTitleContactMe">Leave me a message and i will respond in a timely fashion (as soon as possible) </p>
                <div className="title-sectionContactMe">
                    <label className="TitleLabelContactMe" htmlFor="title">*Title: </label>
                    <br></br>
                    <label htmlFor="title">Select... </label>
                    <select name="MMERGE3" id="title" required>
                    <option value=""></option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Ms">Ms</option>
                    </select>
                </div>
                <br></br>

                <div className="ContactMeFirstName">
                    <label className="FirstNameLabel" htmlFor="firstname">*First Name:</label>
                    <input type="text" name="FNAME" required/>
                </div>
                <br></br>
                <div className="ContactMeLastName">
                    <label className="LastNameLabel" htmlFor="lastname">*Last Name:</label>
                    <input type="text" name="LNAME" required/>
                </div>
                <br></br>
                <div className="ContactMeBusinessEmail">
                    <label className="BusinessEmailLabel" htmlFor="businessemail">*Business or Educational Institute Email:</label>
                    <input type="email" name="MERGE0" onChange={handleEmailInputChange} required></input>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
                <br></br>
                <div className="ContactMeBusinessName">
                    <label className="BusinessNameLabel" htmlFor="businessname">*Business or Educational Institute Name:</label>
                    <input type="text" name="MMERGE4" required/>
                </div>
                <br></br>

                <div className="topic-sectionContactMe">
                    <label className="TopicLabelContactMe" htmlFor="topic">*Topic: </label>
                    <br></br>
                    <select name="MMERGE5" id="topic" required>
                    <option value=""></option>
                    <option value="-">Social Listening Sentiment Analysis Report</option>
                    <option value="Social Listening Sentiment Analysis Report">Social Listening Sentiment Analysis Report</option>
                    <option value="Speaking Enquiry">Speaking Enquiry</option>
                    <option value="Work with my publication">Work with my publication</option>
                    <option value="Help me with my Student Thesis">Help me with my Student Thesis</option>
                    <option value="Other">Other</option>
                    </select><br /><br />
                </div>
                {/* <br></br> */}
                {/* <div className="FileAttachment">
                    <label className="FileLabel" htmlFor="file">File:</label>
                    <br /><br />
                    <input type="file" name="file" id="file" accept=".pdf,.doc,.docx" />
                </div> */}
                {/* <br /><br /> */}

                <div className="ContactMeOccupation">
                    <label className="ContactMeOccuupationLabel" htmlFor="occupation"><em>*Please select your occupation</em></label>
                    <br /><br />
                    <div className="ContactMeradio-labels1">
                        <div className="radio-row1">
                            <label classname= "ContactMeradio1" htmlFor="luxuryinvestor">
                                <input
                                type="radio"
                                name="MMERGE6"
                                id="luxuryinvestor"
                                value="Luxury Investor"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="ContactMespacers">&nbsp;&nbsp;</span>Luxury Investor
                            </label>

                            <label classname= "ContactMeradio2" htmlFor="luxuryleader">
                                <input
                                type="radio"
                                name="MMERGE6"
                                id="luxuryleader"
                                value="Luxury Leader"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="ContactMespacers">&nbsp;&nbsp;</span>Luxury Leader
                            </label>

                            <label classname= "ContactMeradio3" htmlFor="luxuryteacher">
                                <input
                                type="radio"
                                name="MMERGE6"
                                id="luxuryteacher"
                                value="Luxury Teacher"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="ContactMespacers">&nbsp;&nbsp;</span>Luxury Teacher
                            </label>
                        </div>
                        <br></br>

                        
                        <div className="radio-row2">
                            <label classname= "ContactMeradio4" htmlFor="luxurystudent">
                                <input
                                type="radio"
                                name="MMERGE6"
                                id="luxurystudent"
                                value="Luxury Student"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="ContactMespacers">&nbsp;&nbsp;</span>Luxury Student
                            </label>

                            <label classname= "ContactMeradio5" htmlFor="press">
                                <input
                                type="radio"
                                name="MMERGE6"
                                id="press"
                                value="Press"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="ContactMespacers">&nbsp;&nbsp;</span>Press
                            </label>

                            <label classname= "ContactMeradio6" htmlFor="luxuryeventorganiser" style={labelStyle} >
                                <input
                                type="radio"
                                name="MMERGE6"
                                id="luxuryeventorganiser"
                                value="Luxury Event Organiser"
                                onChange={handleOccupationChange}
                                required
            
                                />
                                <span class="ContactMespacers">&nbsp;&nbsp;</span>Luxury Event Organiser
                            </label>
                        </div>

                        <br></br>

                        <div className="radio-row3">
                            <label classname= "ContactMeradio7" htmlFor="other">
                                <input
                                type="radio"
                                name="MMERGE6"
                                id="other"
                                value="Other"
                                onChange={handleOccupationChange}
                                required
                                />
                                <span class="ContactMespacers">&nbsp;&nbsp;</span>Other
                            </label>
                        </div>
                    </div>
                </div>

                <br></br>
                <div className="ContactMeMessageBox">
                    <label className="ContactMeMessageBoxLabel" htmlFor="message"><em>*Please share the details of your enquiry:</em></label><br></br>
                    <textarea className="ContactMemessage-textarea" name="MMERGE7" id="message" cols="25" rows="2.5" required></textarea>
                </div>
                <br></br>
                <div className="ContactMeOther">
                    <label className="ContactMeOther1Label" htmlFor="other"><em>*Required Information</em></label>
                    <br></br>
                    <label className="ContactMeOther2Label" htmlFor="other">TFK will only use your personal information in order to respond to your inquiry</label>
                    <br></br>
                    <label className="ContactMeOther3Label" htmlFor="other">Please consult our <a href="/PrivacyPolicy" class="privacy-link">Privacy Policy</a> for more information</label>
                </div>
                <br></br>
                <div className="ContactMeSubmit">
                    <input type="submit" value="Submit Enquiry" />
                </div>
                <br></br>
                <br></br>
            </form>
        </div>
    </div>
  </div>
  </>
  )
}


