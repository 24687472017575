import * as React from "react"

import Layout from "../components/Layout"
import HeroImageSentiment from "../components/LuxurySentimentPage/HeroImageSentiment"
import ContactForm from "../components/ContactForm/ContactForm"


function ContactMePage({ data }) {
  return (
    <Layout>
    {/* <HeroImageSentiment /> */}
      <ContactForm />
    </Layout>
  )
}

export default ContactMePage